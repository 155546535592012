<template>
  <div class="home">
    <div class="station" @click="downloadApp">
      <!-- <van-nav-bar title="商家入驻" left-arrow /> -->
      <div class="ruzhu">
        <img
          src="https://t.yhaocang.com/upload/wechat_h5/wechatImg/ruzhu@2x.png"
          alt=""
        />
      </div>
      <div class="manage">
        <img
          src="https://t.yhaocang.com/upload/wechat_h5/wechatImg/manage@2x.png"
          alt=""
        />
      </div>
      <div class="btn" @click.stop="downloadApp">立即下载</div>
      <div class="zhanwei"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {},
  data() {
    return {}
  },
  created() {
    console.log(this.$axios)
  },
  methods: {
    downloadApp() {
      window.location.href =
        'https://a.app.qq.com/o/simple.jsp?pkgname=com.miwang.mchyhsc'
    },
  },
}
</script>
<style lang="scss" scoped>
.station {
  background: url(https://t.yhaocang.com/upload/wechat_h5/wechatImg/shopbg@2x.png)
    0 0 no-repeat;
  background-size: 100%;
  height: 1388px;
  .ruzhu {
    width: 443px;
    height: 345px;
    margin: 0 auto 165px;
    img {
      width: 443px;
      height: 345px;
    }
  }
  .manage {
    width: 614px;
    height: 511px;
    margin: 0 auto 168px;

    img {
      width: 614px;
      height: 511px;
    }
  }
  .btn {
    width: 443px;
    height: 97px;
    background: #333333;
    border: 1px solid #eeeeee;
    border-radius: 14px;
    font-size: 35px;
    font-weight: 600;
    color: #fefefe;
    text-align: center;
    line-height: 97px;
    margin: 0 auto 0;
  }
  .zhanwei {
    height: 120px;
    width: 100%;
    background-color: #fff;
  }
}
</style>
