import Vue from 'vue'
import 'vant/lib/index.css'
import { Button } from 'vant'
import { NavBar } from 'vant'
import { Icon } from 'vant'
import { Toast } from 'vant'
Vue.use(Toast)
Vue.use(Icon)
Vue.use(NavBar)
Vue.use(Button)
